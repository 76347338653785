/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'us-east-1',
  aws_cognito_identity_pool_id: 'us-east-1:9d16d0b9-34eb-4bc2-a666-d4d89d044dae',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_hXektTdUL',
  aws_user_pools_web_client_id: '4ngc7297ls1pngpm8hapdv03f9',
  oauth: {},
  aws_user_files_s3_bucket: 'app-map-files',
  aws_user_files_s3_bucket_region: 'us-east-1'
};

export const getObjectUrl = filename => filename && `https://${awsmobile.aws_user_files_s3_bucket}.s3.amazonaws.com/public/${filename}`;

export default awsmobile;
